import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import Banner from "./components/Banner";
import Partners from "./components/Partners";
import XFor from "./components/XFor";
import Meet from "./components/Meet";
// import About from "./components/About";
// import Lab from "./components/Lab";
import Join from "./components/Join";
// import Blog from "./components/Blog";
import Chart from "./components/Chart";
import BgColor from "assets/images/bg-color.svg";
import BgDot from "assets/images/bg-dot.png";

const Containter = styled(Stack)`
  overflow: hidden;
`;

const BgBox = styled(Stack)`
  position: relative;
  
`;

const ImgBg1 = styled("img")`
  position: absolute;
  top: 5%;
  right: -60%;
  width: 100%;
`;

const ImgBg2 = styled("img")`
  position: absolute;
  top: 17%;
  right: -50%;
  width: 1600px;
`;

const ImgBg3 = styled("img")`
  position: absolute;
  top: 40%;
  left: -40%;
  width: 100%;
`;

const ImgBg4 = styled("img")`
  position: absolute;
  top: 55%;
  left: -40%;
  width: 1600px;
`;

const ImgBg5 = styled("img")`
  position: absolute;
  top: 5%;
  left: -40%;
  width: 100%;
  z-index: 1;
`;

const Home = () => {
  return (
    <Containter>
      <Banner />
      <Partners />
      <BgBox>
        <ImgBg1 src={BgColor} alt="" />
        <ImgBg2 src={BgDot} alt="" />
        <ImgBg3 src={BgColor} alt="" />
        <ImgBg4 src={BgDot} alt="" />
        <Chart />
        <XFor />
      </BgBox>
      <Meet />
      {/* <About /> */}
      {/* <Lab /> */}
      <BgBox>
        <ImgBg5 src={BgColor} alt="" />
        <Join />
        {/* <Blog /> */}
      </BgBox>
    </Containter>
  );
};

export default Home;
