import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const LinkIcon: React.FC<IconProps> = ({ width = "11px", height = "11px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        <path d="M1.51465 1H9.99993V9.48528" stroke="#1F7A55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 1L1 10" stroke="#1F7A55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </SvgIcon>
  );
};

export default LinkIcon;
