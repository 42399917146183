import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import { useShow } from "hooks/useShow";
import { Head, SubHead, Desc } from "./Style";
import Transitions from "components/Transitions";

const LabBox = styled(Stack)`
  /* position: relative; */
  position: relative;
  z-index: 2;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 175px 25px 175px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const JoinCon = styled(Stack)`
  margin-top: 100px;
  height: 627px;
  width: 100%;
  position: relative;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: "187px",
      marginTop: "40px",
    },
  })};
`;

const ImgCon = styled("img")`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
`;

const Info = styled(Typography)`
  color: #c6c6c6;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })};
`;

const BannerButton = styled(Button)`
  border-radius: 68px;
  height: 68px;
  min-width: 258px;
  border: 1px solid transparent;
  border-radius: 45px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #0e0e0e, #0e0e0e),
    linear-gradient(90deg, #ffffff 0%, #353535 50%, #6f6f6f 100%);
  box-sizing: border-box;
  margin-top: 60px;
  font-size: 20px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      marginTop: "30px",
      height: "40px",
      minWidth: "150px",
    },
  })};
`;

const Join = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  return (
    <LabBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack
          ref={ref}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Head>Join Stables Labs Campaign</Head>
        </Stack>
        <Transitions delay={200} isShow={isShow}>
          <JoinCon justifyContent="center" alignItems="center" direction="row">
            <ImgCon src={`/images/join-bg.svg?key=${Math.random() * 10}`} />
            <Stack width={upToMd ? 800 : "100%"}>
              <SubHead>Earn Points. Dominate the Ecosystem!</SubHead>
              {upToMd && (
                <Info>
                  Get ready to claim epic rewards! Join USDX & USD0x in Stables Labs Season 2 Points Campaign. 
Earn points, unlock exclusive rewards, and secure your spot in the next-gen stablecoin ecosystem. 
Power up, play smart, and maximize your gains!
                </Info>
              )}
              <Stack
                alignContent="center"
                justifyContent={"center"}
                direction="row"
              >
                <a href="https://app.usdx.money/airdrop" target="_blank">
                  <BannerButton variant="outlined">Join Season 2</BannerButton>
                </a>
              </Stack>
            </Stack>
          </JoinCon>
        </Transitions>
        {!upToMd && (
          <Info mt={30}>
            Ready to claim epic airdrops? It’s time to gear up and join USDX's
            Season I Points Campaign as an early champion. Rack up points,
            unlock exclusive rewards, and secure your place in the next-gen
            ecosystem. Power up, play smart, and win big!
          </Info>
        )}
      </Containter>
    </LabBox>
  );
};

export default Join;
