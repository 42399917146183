import { styled } from "@mui/material/styles";
import { Stack, Typography, Box } from "@mui/material";
import p10T from "assets/images/partners/10T.png";
import BAI from "assets/images/partners/BAI.png";
import ceffu from "assets/images/partners/ceffu.png";
import dragonfly from "assets/images/partners/dragonfly.png";
import gen from "assets/images/partners/gen.png";
import Jeneration from "assets/images/partners/Jeneration.png";
import NGC from "assets/images/partners/NGC.png";
import UOB from "assets/images/partners/UOB.png";
import BaiImg from "assets/images/partners/new/bai.png";
import DraImg from "assets/images/partners/new/dra.png";
import GenImg from "assets/images/partners/new/gen.png";
import JenImg from "assets/images/partners/new/jen.png";
import NgcImg from "assets/images/partners/new/ngc.png";
import UobImg from "assets/images/partners/new/uob.png";

const PartnersBox = styled(Stack)`
  width: 100%;
  margin-top: 100px;
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: '50px',
    },
    [theme.breakpoints.down("md")]: {
      marginTop: '30px',
    },
  })};
`;

const ParOut = styled('div')`
  position: relative;
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  .item-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
    background: #1A1A1A;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      padding: '0 20px',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down("md")]: {
      padding: '0 20px',
      gridTemplateColumns: '1fr 1fr',
    },
  })};
`;

const ImgCon = styled("img")`
  width: auto;
  height: 25px;
`;

const TextCon = styled(Typography)`
  width: auto;
  height: 25px;
  margin-right: 50px;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  line-height: 25px;
  display: inline-block;
`;

const ParInner = styled(Stack)`
  @keyframes move {
    0% {
      left: 0;
    }
    100% {
      left: -1840px;
    }
  }
  position: absolute;
  top: 25px;
  left: 0;
  height: 25px;
  animation: 20s move infinite linear;
  -webkit-animation: 20s move infinite linear;
  &:hover {
    animation-play-state: paused;
  }
`;

const Partners = () => {
  const ParList = [
    <ImgCon src={NgcImg} />,
    <ImgCon src={BaiImg} />,
    <ImgCon src={GenImg} style={{height: '30px'}} />,
    <ImgCon src={UobImg} />,
    <ImgCon src={JenImg} style={{height: '40px'}} />,
    <ImgCon src={DraImg} />,
  ];
  return (
    <PartnersBox justifyContent="center" alignItems="center" direction="row">
      <ParOut>
        {ParList.map((d: any) => (
          <div className="item-box">{d}</div>
        ))}
      </ParOut>
    </PartnersBox>
  );
};

export default Partners;
