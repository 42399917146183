import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const NewLogo: React.FC<IconProps> = ({ width = "145px", height = "33px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="145" height="33" viewBox="0 0 145 33" fill="none">
        <path d="M34.4896 0.0947917C30.7599 0.0947917 27.1304 0.0992767 23.4993 0.0918018C22.5979 0.0903068 21.8998 0.429666 21.3587 1.15622C19.7562 3.31048 18.1372 5.45128 16.5243 7.59806C15.7724 8.60119 15.0204 9.60282 14.2581 10.6194C14.1773 10.5177 14.1146 10.4415 14.0548 10.3623C12.5629 8.37395 11.059 6.39461 9.5851 4.39433C9.09329 3.72758 8.49684 3.40915 7.65822 3.41812C5.25746 3.44652 2.8567 3.42858 0.45743 3.42858H0C0.121084 3.59303 0.197323 3.70067 0.276551 3.80681C3.39036 7.93443 6.50268 12.0635 9.62546 16.1837C9.80634 16.4244 9.81531 16.5694 9.62995 16.8161C6.51763 20.9452 3.41578 25.0833 0.312428 29.2184C0.242169 29.314 0.176395 29.4127 0.0702589 29.5637H0.527689C2.92695 29.5637 5.32771 29.5532 7.72698 29.5712C8.48936 29.5757 9.05891 29.2946 9.51036 28.6861C11.0157 26.6605 12.5345 24.6467 14.0488 22.6285C14.1116 22.5463 14.1833 22.47 14.2625 22.3759C14.3448 22.4805 14.4075 22.5568 14.4673 22.6345C16.756 25.6872 19.0506 28.737 21.3288 31.7957C21.8864 32.5432 22.595 32.902 23.5263 32.899C26.9465 32.887 30.3668 32.8945 33.787 32.8945H34.4149C30.2846 27.3915 26.2021 21.9528 22.1121 16.5051C26.235 11.041 30.3429 5.5948 34.4896 0.0947917ZM28.706 29.9135C28.7419 29.9614 28.7792 30.0077 28.8674 30.1198C27.099 30.1198 25.4098 30.1243 23.7206 30.1079C23.5965 30.1064 23.444 29.9658 23.3558 29.8507C21.4334 27.3003 19.517 24.7454 17.6006 22.1905C16.2373 20.3711 14.8739 18.5502 13.5106 16.7294C13.4523 16.6501 13.397 16.5694 13.3327 16.4767C14.3597 15.1088 15.3792 13.7469 16.4002 12.3865C18.7128 9.30681 21.0298 6.22866 23.3364 3.14304C23.4964 2.92926 23.6578 2.85302 23.9209 2.85451C25.5593 2.86647 27.1977 2.86049 28.8958 2.86049C28.8106 2.99055 28.7583 3.08175 28.6955 3.16547C25.4262 7.50089 22.1584 11.8393 18.8802 16.1687C18.6933 16.4154 18.6799 16.5694 18.8742 16.828C22.1584 21.1844 25.4307 25.5497 28.706 29.9135Z" fill="white"/>
        <path d="M119.796 27.3392L127.203 17.4734L119.796 7.58008H125.185L131.127 15.6772H133.669L139.611 7.58008H145L137.593 17.4734L145 27.3392H139.583L133.669 19.2697H131.127L125.213 27.3392H119.796Z" fill="white"/>
        <path d="M96.6802 27.3375V7.57842H99.9411L108.784 7.55078C112.358 7.55078 115.131 8.38905 117.102 10.0656C119.074 11.7421 120.059 14.2016 120.059 17.4441C120.059 20.6866 119.074 23.1462 117.102 24.8227C115.131 26.4992 112.358 27.3375 108.784 27.3375H96.6802ZM108.48 11.1433H101.102V23.7449H108.48C110.802 23.7449 112.561 23.2383 113.759 22.225C114.956 21.2117 115.555 19.6181 115.555 17.4441C115.555 15.2518 114.956 13.6581 113.759 12.6633C112.561 11.65 110.802 11.1433 108.48 11.1433Z" fill="white"/>
        <path d="M71.0811 27.4768V23.8843L86.9713 23.9119C87.7819 23.9119 88.3991 23.7277 88.8228 23.3592C89.265 22.9723 89.4861 22.3551 89.4861 21.5077C89.4861 20.6602 89.265 20.0522 88.8228 19.6837C88.3991 19.2969 87.7819 19.1034 86.9713 19.1034H77.0779C75.0329 19.1034 73.4393 18.6152 72.2971 17.6387C71.1732 16.6439 70.6113 15.1884 70.6113 13.2724C70.6113 11.3564 71.1732 9.91014 72.2971 8.9337C73.4393 7.93884 75.0329 7.44141 77.0779 7.44141H92.6088V11.034L77.5477 11.0063C76.7555 11.0063 76.1383 11.1906 75.6962 11.559C75.254 11.9091 75.0329 12.4802 75.0329 13.2724C75.0329 14.0646 75.254 14.6449 75.6962 15.0134C76.1383 15.3635 76.7555 15.5385 77.5477 15.5385H87.4411C89.4861 15.5385 91.0705 16.0451 92.1943 17.0584C93.3366 18.0533 93.9077 19.5364 93.9077 21.5077C93.9077 23.4605 93.3366 24.9436 92.1943 25.9569C91.0705 26.9702 89.4861 27.4768 87.4411 27.4768H71.0811Z" fill="white"/>
        <path d="M53.0398 27.4773C51.0316 27.4773 49.4472 27.2194 48.2865 26.7035C47.1259 26.1693 46.2968 25.3678 45.7994 24.2993C45.3019 23.2307 45.0532 21.8858 45.0532 20.2646V7.58008H49.4748V20.2646C49.4748 21.6647 49.7972 22.6228 50.4421 23.1386C51.1053 23.636 52.2199 23.8848 53.7859 23.8848H59.1195C60.6855 23.8848 61.7909 23.636 62.4357 23.1386C63.0989 22.6228 63.4305 21.6647 63.4305 20.2646V7.58008H67.8522V20.2646C67.8522 21.8858 67.6034 23.2307 67.106 24.2993C66.6086 25.3678 65.7795 26.1693 64.6189 26.7035C63.4582 27.2194 61.8738 27.4773 59.8656 27.4773H53.0398Z" fill="white"/>
      </svg>
    </SvgIcon>
  );
};

export default NewLogo;
